import { TextField, Box, useTheme, useMediaQuery, Button } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import styles from './searchBar.styles'

const SingleInputForm = ({
  id,
  name,
  value,
  onChangeValue,
  placeholder,
  onSubmit,
  startAdornment,
  endAdornment,
}) => {
  const texts = useTexts()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      id={id}
      component='form'
      onSubmit={onSubmit}
      sx={styles.singleInputForm}
      className='singleInputForm'
    >
      <TextField
        id={name}
        name={name}
        className='singleInputForm'
        fullWidth
        value={value}
        onChangeCapture={(e) => onChangeValue(e.target.value)}
        placeholder={placeholder}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
      />
      <Button
        size='small'
        color='primary'
        type='submit'
        className='singleInputForm'
      >
        {widerThanTablet ? (
          texts.searchAction()
        ) : (
          <Icons.Search color='neutral.darkest' />
        )}
      </Button>
    </Box>
  )
}

export default SingleInputForm
