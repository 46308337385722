import PropTypes from 'prop-types'
import {
  Card,
  Box,
  Typography,
  CardActionArea,
  useMediaQuery,
} from '@mui/material'

import * as PT from '../../propTypes'
import theme from '../../../theme'
import MetaInfoDetails from './MetaInfoDetails'
import styles from './articleCard.styles'

export const ARTICLES_DATE_TYPE = {
  READ: 'read',
  PUBLISHED: 'published',
}

const articleCardPropTypes = {
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  onClick: PropTypes.func,
  dateType: PropTypes.oneOf(Object.values(ARTICLES_DATE_TYPE)),
  showPriceLabel: PropTypes.bool,
}

export const ArticleCard = ({
  id,
  article,
  onClick,
  dateType = ARTICLES_DATE_TYPE.PUBLISHED,
  showPriceLabel = false,
  withDescription = false,
}) => {
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <CardActionArea
      id={id}
      onClick={() => onClick(article?.url)}
      sx={styles.root}
    >
      <Card variant='plain' sx={styles.card}>
        <Box sx={{ ...styles.flexColumn, justifyContent: 'space-between' }}>
          <Box sx={{ ...styles.flexColumn, justifyContent: 'flex-start' }}>
            {Boolean(article?.mediaImage) && (
              <Box sx={styles.mediaImage}>
                <img
                  src={article?.mediaImage}
                  alt={`logo del medio ${article?.mediaName ?? ''}`}
                />
              </Box>
            )}
            <Typography
              variant='cardHeading'
              sx={withDescription ? styles.clampedTitle : {}}
            >
              {article?.title}
            </Typography>
            {widerThanTablet && withDescription && article?.description && (
              <Typography>{article?.description}</Typography>
            )}
          </Box>
          <MetaInfoDetails
            article={article}
            dateType={dateType}
            showPriceLabel={showPriceLabel}
          />
        </Box>
        {Boolean(article?.image) && (
          <Box sx={styles.articleImage}>
            <img src={article?.image} alt='Imagen del artículo' />
          </Box>
        )}
      </Card>
    </CardActionArea>
  )
}
ArticleCard.propTypes = articleCardPropTypes
