import { Box, Button } from '@mui/material'

import { useTexts } from '../../../../../../texts'
import { Picture } from '../../../../../components'
import imageAvif from '../../../../../../assets/images/coffee.avif'
import imageWebp from '../../../../../../assets/images/coffee.webp'
import imagePng from '../../../../../../assets/images/coffee.png'
import styles from './errorStep.styles.js'

const ErrorStep = ({ onRetry, onCancelProcess }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Box sx={styles.wrapPicture}>
        <Picture
          width={418}
          height={367}
          avif={imageAvif}
          webp={imageWebp}
          fallback={imagePng}
        />
      </Box>
      <Box sx={styles.actions}>
        <Button fullWidth color='secondary' onClick={onRetry}>
          {texts.getPurchaseErrorRetryAction()}
        </Button>
        <Button fullWidth onClick={onCancelProcess}>
          {texts.getPurchaseErrorCancelProcessAction()}
        </Button>
      </Box>
    </Box>
  )
}

export default ErrorStep
