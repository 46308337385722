import { CircularProgress, InputAdornment, Box, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { FormSkinWrapper, TextField, Alert } from '../../../components'
import { Icons } from '../../../../assets'
import { useTexts } from '../../../../texts'
import { Auth } from '../../../../business'
import { useFormV2, composeValidators, validators } from '../../../../forms'
import styles from './forgottenPassword.styles'

const FORGOTTEN_PASSWORD_ENTER_EMAIL_FORM_ID =
  EVENTS_NAMES.PA_FORM_SUBMIT_FP_SEND_CODE

const useEnterEmailForm = () => {
  const texts = useTexts()
  const { isValidEmail, isFilled } = validators

  const FORM_VALIDATION_CONFIG = {
    email: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidEmail, texts.getErrorEmail()],
    ]),
  }

  const submitForm = async (formValues) =>
    await Auth.initForgottenPassword(formValues.email)

  return useFormV2(
    FORGOTTEN_PASSWORD_ENTER_EMAIL_FORM_ID,
    submitForm,
    FORM_VALIDATION_CONFIG,
  )
}

const EnterEmailStep = ({
  isDarkMode,
  emailDefaultValue,
  onContinue,
  onGoToLogin,
}) => {
  const texts = useTexts()

  const { status, isSubmitSuccess, submitForm } = useEnterEmailForm()

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  if (isSubmitSuccess()) {
    onContinue(status.formValues.email)
  }

  return (
    <form
      id={FORGOTTEN_PASSWORD_ENTER_EMAIL_FORM_ID}
      onSubmit={handleSubmit}
      noValidate
    >
      <FormSkinWrapper
        skin={isDarkMode ? 'dark' : 'light'}
        sx={styles.wrapFields}
      >
        <TextField
          id='email'
          name='email'
          label={texts.getEmailLabel()}
          type='email'
          autoComplete='email'
          defaultValue={emailDefaultValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icons.Envelope />
              </InputAdornment>
            ),
          }}
          helperText={status.fieldErrors.email}
          error={Boolean(status.fieldErrors.email)}
          inputProps={{ inputMode: 'email' }}
          required
        />
        {Boolean(status.formError) && (
          <Alert variant='error' text={texts.getUnexpectedError()} />
        )}
        <Box sx={styles.actions}>
          <LoadingButton
            type='submit'
            color={isDarkMode ? undefined : 'secondary'}
            loading={status.isSubmitting}
            disabled={status.isSubmitting || Boolean(status.formError)}
            loadingIndicator={<CircularProgress size={16} />}
            fullWidth
          >
            {texts.getForgottenPasswordEnterEmailAction()}
          </LoadingButton>
          <Button
            variant='text'
            color={isDarkMode ? 'secondary' : undefined}
            onClick={onGoToLogin}
            fullWidth
          >
            {texts.getLoginFormCtaAction()}
          </Button>
        </Box>
      </FormSkinWrapper>
    </form>
  )
}
export default EnterEmailStep
