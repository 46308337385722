import PropTypes from 'prop-types'
import { Typography, Box, Button } from '@mui/material'

import { Icons } from '../../../assets'
import styles, { variantStyles } from './card.styles'

const cardPropTypes = {
  title: PropTypes.string,
  renderContent: PropTypes.func,
  variant: PropTypes.oneOf([
    'light',
    'light_error',
    'dark',
    'dark_error',
    'success',
  ]),
  type: PropTypes.oneOf(['user', 'balance']),
  actions: PropTypes.shape({
    primary: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    secondary: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  }),
  id: PropTypes.string,
}

const getButtonPropsVariant = (variant, actionType) => {
  if (actionType === 'primary') {
    if (variant === 'light' || variant === 'success') {
      return { color: 'secondary' }
    } else {
      return { color: 'primary' }
    }
  }
  if (actionType === 'secondary') {
    if (variant === 'light' || variant === 'success') {
      return { variant: 'outlined' }
    } else {
      return { variant: 'outlined' }
    }
  }
}

const Card = ({
  title,
  renderContent = () => null,
  variant = 'light',
  type,
  actions,
  id,
}) => {
  return (
    <Box
      sx={(theme) => ({ ...variantStyles(theme)[variant], ...styles.root })}
      id={id}
    >
      <Box sx={styles.content}>
        <Box sx={styles.mainContent}>
          {Boolean(title) && (
            <Typography variant={type === 'balance' ? 'h3' : 'h4'}>
              {title}
              {type === 'balance' && <span className='euro'> €</span>}
            </Typography>
          )}
          {renderContent()}
        </Box>
        <Box sx={styles.icon}>
          {type === 'user' ? (
            <Icons.User2 className='icon' />
          ) : type === 'balance' ? (
            <Icons.Coins2 className='icon' />
          ) : null}
        </Box>
      </Box>

      {Boolean(actions) && (
        <Box sx={styles.actions}>
          {Object.entries(actions).map(([actionType, action]) => (
            <Button
              key={actionType}
              fullWidth
              {...getButtonPropsVariant(variant, actionType)}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  )
}

Card.propTypes = cardPropTypes

export default Card
