const styles = {
  error: {
    '--chip-color': (theme) => theme.palette.error.lightest,
    '--chip-backgroundColor': (theme) => theme.palette.error.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.error.dark,
  },
  success: {
    '--chip-color': (theme) => theme.palette.neutral.white,
    '--chip-backgroundColor': (theme) => theme.palette.success.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.success.dark,
  },
  default: {
    '--chip-color': (theme) => theme.palette.neutral.dark,
    '--chip-backgroundColor': (theme) => theme.palette.neutral.light,
    '--chip-hover-backgroundColor': (theme) => theme.palette.neutral.lightest,
  },
  inverted: {
    '--chip-color': (theme) => theme.palette.neutral.black,
    '--chip-backgroundColor': (theme) => theme.palette.neutral.white,
    '--chip-hover-backgroundColor': (theme) => theme.palette.neutral.light,
  },
  warning: {
    '--chip-color': (theme) => theme.palette.neutral.black,
    '--chip-backgroundColor': (theme) => theme.palette.primary.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.warning.dark,
  },
  info: {
    '--chip-color': (theme) => theme.palette.info.lightest,
    '--chip-backgroundColor': (theme) => theme.palette.info.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.info.dark,
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 0.625,
    py: 0.25,
    px: 1,
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    letterSpacing: '0.1em',
    borderRadius: (theme) => theme.spacing(2),
    color: 'var(--chip-color)',
    backgroundColor: 'var(--chip-backgroundColor)',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: 'var(--chip-hover-backgroundColor, inherit)',
    },
  },
}

export default styles
