import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import { OnboardingLayout } from '../../components'
import styles from './onboarding.styles'

const onboardingStepPropTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    image: PropTypes.string.isRequired,
    caption: PropTypes.string,
  }),
  image: PropTypes.node.isRequired,
  onClickNextStep: PropTypes.func,
  renderStep: PropTypes.func,
}

const View = ({ title, text, avatar, image, onClickNextStep, renderStep }) => {
  const texts = useTexts()

  return (
    <OnboardingLayout
      avatar={avatar}
      title={title}
      renderLeftSectionContent={() => (
        <>
          <Typography variant='body2' sx={styles.leftSection_text}>
            {text}
          </Typography>
          <Box sx={styles.leftSection_actions}>
            <Button color='primary' onClick={onClickNextStep}>
              {texts.next()}
            </Button>
            {renderStep()}
          </Box>
        </>
      )}
      renderRightSectionContent={() => (
        <Box sx={styles.rightSection_content}>{image}</Box>
      )}
    />
  )
}

View.propTypes = onboardingStepPropTypes

export default View
