import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { openMediaLink } from '../../../utils'
import { User } from '../../../../business'
import { ArticlesList } from '../../../components'

const SectionPage = () => {
  const { sectionId } = useParams()

  const fetchArticlesBySection = useMemo(
    () => async (pagination) =>
      await User.getArticlesBySection(sectionId, pagination),
    [sectionId],
  )

  const articlesData = User.useArticlesList({
    fetchFn: fetchArticlesBySection,
    withLastPageValidationHack: true,
  })

  const handleOpenArticle = (article) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_ARTICLE,
          interface: 'topic',
          articleId: article.articleId,
          mediaId: article.mediaProviderId,
        },
      }),
    )

    openMediaLink(article.url, document.location.pathname)
  }

  return (
    <ArticlesList
      name={`section-${sectionId}`}
      onClickArticle={handleOpenArticle}
      {...articlesData}
    />
  )
}

export default SectionPage
