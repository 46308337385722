import { Box } from '@mui/material'

export const PAGE_CONTENT_MARGINS = {
  mt: {
    xs: 2,
    md: 2.5,
  },
  mb: {
    xs: 3,
    md: 4,
  },
}

const styles = (theme) => ({
  ...PAGE_CONTENT_MARGINS,

  display: 'grid',
  gridTemplateColumns: '1fr var(--container-max-width) 1fr',
  gridRowGap: (theme) => ({
    xs: theme.spacing(2),
    md: theme.spacing(3),
  }),

  '& h2': {
    textAlign: {
      md: 'center',
    },
  },
  '& > *': {
    gridColumn: 2,
  },

  '& .full-bleed': {
    width: '100%',
    gridColumn: '1 / 4',
    borderRadius: 0,
  },

  '& .mobile-full-bleed': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      gridColumn: '1 / 4',
      borderRadius: 0,
    },
  },
})

const PageContent = ({ children, sx }) => (
  <Box component='main' sx={(theme) => ({ ...styles(theme), ...sx })}>
    {children}
  </Box>
)

export default PageContent
