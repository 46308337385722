import { User } from '../../../business'

const PrivateDataProvider = ({ children }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>{children}</User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export default PrivateDataProvider
