import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'

import styles from './footer.styles'

const responseProptypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  ctaText: PropTypes.string,
  onClickCta: PropTypes.func,
}

const ResponseView = ({ title, message, ctaText, onClickCta }) => {
  return (
    <Box sx={styles.view} id='view'>
      <Typography component='h4'>{title}</Typography>
      <Typography>{message}</Typography>
      <Button color='secondary' onClick={onClickCta}>
        {ctaText}
      </Button>
    </Box>
  )
}

ResponseView.propTypes = responseProptypes

export default ResponseView
