import { Session } from '../business'
import { nativeApps } from '../thirdPartyIntegration'
import dispatchEvent from './index'

const pushDataLayerVariable = (varName, varValue) =>
  window.gtag({ [varName]: varValue })

const pushDataLayerUserSubVariable = (value) =>
  pushDataLayerVariable('userSub', value)

document.addEventListener('appLoad', () => {
  const userSub = Session.getUserSub()
  if (Boolean(userSub)) {
    pushDataLayerUserSubVariable(userSub)
  }
})

document.addEventListener('sessionStored', () => {
  pushDataLayerUserSubVariable(Session.getUserSub())
})

let ssoData = {}

const initializeDetailWithUserSub = () => {
  const userSub = Session.getUserSub()

  if (Boolean(userSub)) {
    pushDataLayerUserSubVariable(userSub)
  }
  return Boolean(userSub) ? { userSub } : {}
}

// NEW:

document.addEventListener('payperAnalyticsPrivateEvent', ({ detail }) => {
  const { name, ...restDetail } = detail
  dispatchEvent(name, {
    userSub: Session.getUserSub(),
    appPlatform: nativeApps.getAppPlatform(),
    ...restDetail,
  })
})

document.addEventListener('payperAnalyticsEvent', ({ detail }) => {
  const { name, ...restDetail } = detail
  const userSub = Session.getUserSub()
  if (userSub) {
    restDetail.userSub = userSub
  }

  dispatchEvent(name, {
    ...restDetail,
  })
})

document.addEventListener('payperAnalyticsPublicEvent', ({ detail }) => {
  const { name, ...restDetail } = detail
  dispatchEvent(name, {
    ...restDetail,
  })
})

// TO REVIEW:
const saveDetailDataLocally = ({ detail }) => {
  ssoData = detail
}

document.addEventListener('ssoLoad', saveDetailDataLocally)

document.addEventListener('leaveRegister', ({ detail: stepName }) => {
  dispatchEvent('leave_register', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    stepName,
  })
})

document.addEventListener('leaveForgottenPassword', ({ detail: stepName }) => {
  dispatchEvent('leave-forgotten-password', {
    ...ssoData,
    stepName,
  })
})

document.addEventListener('formSubmitted', ({ detail: formData }) => {
  dispatchEvent('form_submitted', {
    ...ssoData,
    ...formData,
  })
})

document.addEventListener(
  'registerConfirmEmailForm',
  ({ detail: formData }) => {
    dispatchEvent('register-confirm-email-form', {
      ...ssoData,
      ...formData,
    })
  },
)

document.addEventListener('registerBankCardForm', ({ detail: formData }) => {
  dispatchEvent('register-bank-card-form', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    ...formData,
  })
})

document.addEventListener('registerTopUpBtn', ({ detail: formData }) => {
  dispatchEvent('register-top-up-btn', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    ...formData,
  })
})

document.addEventListener('registerSkipTopUpBtn', ({ detail: formData }) => {
  dispatchEvent('register-skip-top-up-btn', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    ...formData,
  })
})

document.addEventListener('dashboardGoToMediaPage', ({ detail }) => {
  dispatchEvent('dashboard-go-to-media-page', {
    ...initializeDetailWithUserSub(),
    ...detail,
  })
})
