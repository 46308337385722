const MuiTypography = {
  defaultProps: {
    variantMapping: {
      subtitle1: 'h4',
      subtitle2: 'p',
      body1: 'p',
      cardHeading: 'h6',
      small: 'small',
      smallLabel: 'p',
    },
  },
}

export default MuiTypography
