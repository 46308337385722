import PropTypes from 'prop-types'
import {
  Box,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import * as PT from '../../propTypes'
import { Icons } from '../../../assets'
import styles from './appBarNavigation.styles'

const APP_BAR_NAVIGATION_ID = 'app-bar-navigation'

const AppBarNavigationPropTypes = {
  actions: PT.NavigationActions,
  onClickMenuItem: PropTypes.func,
  onClickHome: PropTypes.func,
  activeAction: PT.NavigationItemName,
}

const AppBarNavigation = ({
  actions = [],
  buttonActions = [],
  onClickMenuItem,
  onClickHome,
  activeAction,
}) => {
  return (
    <Box component='header' sx={styles.root} id={APP_BAR_NAVIGATION_ID}>
      <Box sx={styles.toolbar}>
        <Box sx={styles.wrapLogo}>
          <Button sx={styles.logo} onClick={onClickHome}>
            <Icons.PayperLogo
              aria-label='Payper logo'
              color='#121212'
              height={24}
            />
          </Button>
        </Box>
        <Box sx={styles.menuList}>
          {actions.map((item) => (
            <MenuItem
              key={item.path}
              onClick={() => onClickMenuItem(item)}
              selected={item.name === activeAction}
            >
              {!!item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
          {buttonActions.map((item) => (
            <Button
              key={item.name}
              color='secondary'
              onClick={item.onClick}
              sx={styles.menuButton}
            >
              {item.label}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

AppBarNavigation.propTypes = AppBarNavigationPropTypes

export default AppBarNavigation
