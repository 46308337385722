const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 'var(--spacing-m)',
    width: 'min(calc(100% - 2.5rem), var(--content-L-max-width))',
    mx: 'auto',
    height: '100%',
    py: { xs: 'var(--spacing-m)', md: 'var(--spacing-l)' },
  },
  content: {
    flexGrow: 2,
  },
  articlesList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 'var(--spacing-s)',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: (theme) => ({
      xs: theme.spacing(1),
      lg: theme.spacing(1.5),
    }),
    mb: (theme) => ({
      xs: theme.spacing(2),
      lg: theme.spacing(3),
    }),
    '&  button': {
      width: (theme) => ({
        xs: '100%',
        md: `min(100%, ${theme.spacing(19.5)})`,
      }),
      mx: 'auto',
    },
  },
}

export default styles
