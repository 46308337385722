import { backofficeEndpoint } from '../../config'

import fetchWithAuthorization, {
  InvalidTokenError,
} from '../fetchWithAuthorization'
import InvalidOldPasswordError from './InvalidOldPasswordError'
import RedsysError from './RedsysError'

export const getPersonalData = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user`, {
    headers: authHeaders,
  })
  if (response.ok) {
    return await response.json()
  }

  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const getArticlesBySection = fetchWithAuthorization(
  async (authHeaders, sectionId, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      offset: pagination?.offset,
      limit: pagination?.limit,
    })
    const response = await fetch(
      `${backofficeEndpoint}/articles/section/${sectionId}?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticlesBySearch = fetchWithAuthorization(
  async (authHeaders, searchValue, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      value: searchValue,
      offset: pagination?.offset,
      limit: pagination?.limit,
    })
    const response = await fetch(
      `${backofficeEndpoint}/articles/search?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticlesForTopic = fetchWithAuthorization(
  async (authHeaders, topic, pagination = {}) => {
    const searchParams = new URLSearchParams({
      offset: pagination?.offset,
      limit: pagination?.limit,
      value: topic,
    })
    const response = await fetch(
      `${backofficeEndpoint}/articles/topic?${searchParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticlesViewedByUser = fetchWithAuthorization(
  async (authHeaders, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      offset: pagination?.offset,
      limit: pagination?.limit,
    })
    const response = await fetch(
      `${backofficeEndpoint}/user/viewed-article?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getUserFavoriteArticles = fetchWithAuthorization(
  async (authHeaders, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      limit: pagination?.limit,
      offset: pagination?.offset,
    })
    const response = await fetch(
      `${backofficeEndpoint}/user/favorite-article?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const updatePersonalData = fetchWithAuthorization(
  async (authHeaders, personalData) => {
    const response = await fetch(`${backofficeEndpoint}/user/personal-data`, {
      method: 'PUT',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(personalData),
    })
    if (response.ok) {
      return
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const fetchPurchases = fetchWithAuthorization(
  async (authHeaders, pagination) => {
    const searchParams = new URLSearchParams(pagination)

    const response = await fetch(
      `${backofficeEndpoint}/user/article-purchases?${searchParams.toString()}`,
      {
        method: 'GET',
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    throw new Error('Something went wrong')
  },
)

export const fetchArticleDetail = fetchWithAuthorization(
  async (authHeaders, { id, mediaId }) => {
    const response = await fetch(
      `${backofficeEndpoint}/user/articles/media/${mediaId}/article/${id}`,
      {
        method: 'GET',
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticleAuthorization = fetchWithAuthorization(
  async (authHeaders, { id, mediaId }) => {
    const response = await fetch(
      `${backofficeEndpoint}/articles/media/${mediaId}/article/${id}/authorization`,
      {
        method: 'GET',
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const purchaseArticle = fetchWithAuthorization(
  async (authHeaders, { id, mediaId, headline, url }) => {
    const response = await fetch(
      `${backofficeEndpoint}/articles/media/${mediaId}/article/${id}/purchase`,
      {
        method: 'POST',
        headers: {
          ...authHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ headline, url }),
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const changePassword = fetchWithAuthorization(
  async (authHeaders, { oldPassword, newPassword }) => {
    const response = await fetch(`${backofficeEndpoint}/user/change-password`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    })

    if (response.ok && response.status === 200) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 400) {
      const error = await response.json()
      if (error.code === 'NotAuthorizedException') {
        throw new InvalidOldPasswordError()
      }
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    throw new Error('Something went wrong')
  },
)

const checkIsBankCardExpired = ({
  month: expiryDateMonth,
  year: expiryDateYear,
}) =>
  expiryDateYear < new Date().getFullYear() ||
  (expiryDateYear === new Date().getFullYear() &&
    expiryDateMonth - 1 < new Date().getMonth())

export const fetchBankCard = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/bank-card`, {
    method: 'GET',
    headers: authHeaders,
  })
  if (response.ok) {
    const data = await response.json()

    const formattedData = {
      ...data,
      expiryDate: `${data.expiryDate.month
        .toString()
        .padStart(2, '0')}/${data.expiryDate.year.toString().slice(2)}`,
      isExpired: checkIsBankCardExpired(data.expiryDate),
    }
    return formattedData
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const fetchBalance = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/wallet-balance`, {
    method: 'GET',
    headers: authHeaders,
  })

  if (response.ok) {
    return await response.text()
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const fetchActiveBonus = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/active-bonus`, {
    method: 'GET',
    headers: authHeaders,
  })

  if (response.ok) {
    return await response.json()
  }
  if (!response.ok && response.status === 404) {
    return null
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const fetchActivePartner = async () => {
  const activeBonus = await fetchActiveBonus()

  if (activeBonus === null || !activeBonus?.detail?.partnerId) {
    return null
  } else {
    const response = await fetch(
      `${backofficeEndpoint}/partners/${activeBonus.detail.partnerId}`,
    )

    if (response.ok) {
      return await response.json()
    }
    if (!response.ok && response.status === 404) {
      return null
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  }
}

export const updateAccountBalance = fetchWithAuthorization(
  async (authHeaders, amount, applePayPaymentData) => {
    const body = { amount }
    if (Boolean(applePayPaymentData)) {
      body.applePayPaymentData = applePayPaymentData
    }

    const response = await fetch(`${backofficeEndpoint}/user/balance`, {
      method: 'PUT',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (response.ok) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 400) {
      const bodyResponse = await response.json()
      if (bodyResponse.code === 'PaymentServiceError') {
        throw new RedsysError(bodyResponse.error)
      }
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }

    throw new Error('Something went wrong')
  },
)

export const createBonus = fetchWithAuthorization(
  async (authHeaders, bonusToken, partnerId) => {
    const response = await fetch(`${backofficeEndpoint}/user/bonus`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ partnerId, bonusToken }),
    })

    if (response.ok) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const createPayperBonus = fetchWithAuthorization(
  async (authHeaders, bonusToken) => {
    const response = await fetch(`${backofficeEndpoint}/user/payper-bonus`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ bonusToken }),
    })

    if (response.ok) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const sendEmail = fetchWithAuthorization(
  async (authHeaders, emailData) => {
    const response = await fetch(`${backofficeEndpoint}/user/send-email`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    })

    if (response.ok && response.status === 204) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    if (!response.ok) {
      throw new Error('email could not be sent')
    }
  },
)

export const validateApplePayMerchant = fetchWithAuthorization(
  async (authHeaders, validationUrl) => {
    const response = await fetch(
      `${backofficeEndpoint}/user/apple-pay/merchant-validation`,
      {
        method: 'POST',
        headers: {
          ...authHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ validationUrl }),
      },
    )

    if (response.ok) {
      return response.json()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    throw new Error(
      `Error fetching ApplePay merchant session: ${response.error}`,
    )
  },
)

export const updateChromeExtInstallationStatus = fetchWithAuthorization(
  async (authHeaders, status) => {
    const response = await fetch(
      `${backofficeEndpoint}/user/integration/chrome-ext`,
      {
        method: 'PATCH',
        headers: {
          ...authHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(status),
      },
    )

    if (response.ok) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)
