import { DocumentCookies } from '../App/utils'

const MAGIC_URL = process.env.REACT_APP_PAYPER_MAGIC_URL
const PAYPER_MAGIC_COOKIE = {
  key: 'payper_magic_auth',
}

export const isActive = () =>
  Boolean(new DocumentCookies().get(PAYPER_MAGIC_COOKIE.key))

export const redirect = (mediaUrl, originUrlPathname) => {
  let redirectUrl = mediaUrl
  if (MAGIC_URL) {
    const searchParams = { url: mediaUrl }
    if (originUrlPathname) {
      searchParams.referrer = originUrlPathname
    }
    const urlSearchParams = new URLSearchParams(searchParams)
    redirectUrl = `${MAGIC_URL}/magic-browse?${urlSearchParams.toString()}`
  }
  window.location = redirectUrl
}
