export const landscapeLayout = {
  width: (theme) => `min(100%, ${theme.spacing(44.25)})`,
  height: (theme) => `min(100%, ${theme.spacing(39.25)})`,
  my: 'auto',
  mx: 'auto',
}
export const LANDSCAPE_BORDER_PADDING = 8.875

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: '100vh',
    '& > div': {
      height: '100%',
    },
    minWidth: (theme) => theme.spacing(65),
    overflowX: 'scroll',
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Safari and Chrome */,
    },
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'neutral.white',

    '& > div': {
      ...landscapeLayout,
      pl: (theme) => theme.spacing(LANDSCAPE_BORDER_PADDING),
      pr: '40px',
    },

    '& h3': {
      mt: 1.6875,
    },
  },
  leftSection_payperLogo: {
    mt: '32px',
    mb: '64px',
  },
  leftSection_avatar: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,

    '& div': {
      borderRadius: '50%',
      height: 94,
      width: 94,
      overflow: 'hidden',

      '& img': {
        height: 94,
        width: 94,
        objectFit: 'cover',
        transform: 'scale(1.4) translateY(10%)',
      },
    },

    '& p': {
      fontFamily: 'ClashDisplay-Medium',
      width: (theme) => theme.spacing(12),
    },
  },
  leftSection_titleUnderline: {
    mt: 0.5,
    mr: (theme) => theme.spacing(10),
    height: 6,
    backgroundColor: 'primary.main',
  },

  rightSection: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'neutral.lightest',
  },
}

export default styles
