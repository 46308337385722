const styles = {
  searchBar: {
    color: 'neutral.darkest',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    columnGap: { xs: 0.5, md: 1 },
    '& #search-form': {
      flexGrow: 2,
    },
  },
  iconButton: {
    '--button-size': { xs: 'var(--size-buttonS)', md: 'var(--size-buttonM)' },
    '--icon-size': 'calc(var(--button-size) - 10px)',

    height: 'var(--button-size)',
    width: 'var(--button-size)',
    '& svg': {
      height: 'var(--icon-size)',
      width: 'var(--icon-size)',

      color: 'neutral.darkest',
    },
  },
  singleInputForm: {
    display: 'flex',
    height: 56,
    color: 'neutral.darkest',

    '& .MuiInputBase-root': {
      p: '12px',
      pr: { xs: '8px', md: '12px' },
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '&:hover': {
        boxShadow: 'none',
      },

      '& input': {
        p: 0,
      },
      '& .MuiInputAdornment-root button': {
        p: '4px',
      },
    },

    '& button[type="submit"]': {
      alignSelf: 'auto',
      color: 'neutral.darkest',
      p: { xs: 0, md: 'inherit' },
      minWidth: { xs: 48, md: 103 },
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}

export default styles
