import formSkinWrapperStyles from '../FormSkinWrapper/formSkinWrapper.styles'

const styles = {
  dark: {
    ...formSkinWrapperStyles.dark,
    '--root-bgColor': (theme) => theme.palette.neutral.black,
    '--link-color': (theme) => theme.palette.neutral.white,
  },
  light: {
    ...formSkinWrapperStyles.light,
    '--root-bgColor': (theme) => theme.palette.neutral.light,
    '--link-color': (theme) => theme.palette.neutral.darkest,
  },
  root: {
    color: 'var(--link-color)',
    backgroundColor: 'var(--root-bgColor)',

    '& h4': {
      fontFamily: 'ClashDisplay-Medium',
      fontSize: 'var(--typography-typeScale-l)',
      fontWeight: 'var(--typography-fontWeight-semiBold)',
      lineHeight: 'var(--typography-lineHeight-m)',
    },
    '& p': {
      mb: 1,
    },
    '& button': {
      width: {
        xs: '100%',
        md: 'var(--containers-contentSMaxWidth)',
      },
    },
  },
  container: {
    width: { xs: '100%', md: 'var(--containers-containerMaxWidth)' },
    mx: 'auto',
    p: {
      xs: 'var(--spacing-xxl) var(--spacing-l)',
      md: 'var(--spacing-xxl) 0',
    },

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: {
      xs: 'var(--spacing-xxl)',
      md: 'var(--spacing-xl)',
    },
  },
  guarantorsView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: {
      xs: 'var(--spacing-s)',
      md: 'var(--spacing-s)',
    },
    width: { xs: '100%', md: 'var(--containers-contentLMaxWidth)' },
    mx: 'auto',
    '& p': {
      mb: 0,
    },
  },
  guarantorLogo: {
    width: (theme) => `min(calc(100% - ${theme.spacing(2)}), 312px)`,
    mx: 'auto',
    '& img': {
      objectFit: 'cover',
      width: '100%',
    },
  },

  view: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: {
      xs: 'var(--spacing-ml)',
      md: 'var(--spacing-l)',
    },
    width: { xs: '100%', md: 'var(--containers-contentLMaxWidth)' },
    mx: 'auto',
    '& p': {
      mb: 0,
    },
  },
  policiesLinks: {
    backgroundColor: (theme) =>
      `var(--root-bgColor, ${theme.palette.neutral.black})`,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    justifyContent: {
      md: 'space-evenly',
    },
    p: 0,
    gap: 1,
    listStyleType: 'none',

    '& a': {
      color: (theme) => `var(--link-color, ${theme.palette.neutral.white})`,
      textDecoration: 'none',
    },
  },

  groupFields: {
    display: 'grid',
    gridTemplateRows: {
      xs: 'auto auto',
      md: '1fr',
    },
    gridTemplateColumns: {
      md: '220px 1fr',
    },
    rowGap: {
      xs: 0.0625,
      md: 0,
    },
    columnGap: {
      md: 0.0625,
    },

    '& > .MuiFormControl-root:nth-of-type(1)': {
      '& .MuiInputBase-root': {
        borderTopRightRadius: {
          md: 0,
        },
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: (theme) => ({
          xs: 0,
          md: theme.shape.borderRadius,
        }),
      },
    },
    '& > .MuiFormControl-root:nth-of-type(2)': {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: (theme) => ({
          xs: 0,
          md: theme.shape.borderRadius,
        }),
        borderBottomLeftRadius: {
          md: 0,
        },
      },
    },
  },
  groupErrors: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.625),
    marginTop: (theme) => theme.spacing(0.5),
    marginLeft: (theme) => theme.spacing(0.75),
    marginRight: (theme) => theme.spacing(0.75),
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'error.main',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
    },
  },
}

export default styles
