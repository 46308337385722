import { IconButton, Typography, Box } from '@mui/material'

import { Icons } from '../../../assets'
import styles from './secondaryPage.styles'

const NavigationSecondary = ({ id, title, onClickGoBack }) => {
  return (
    <Box sx={styles.navRoot} id={id}>
      <Box sx={styles.navContent}>
        <IconButton
          aria-label='volver atrás'
          onClick={onClickGoBack}
          sx={styles.navBackButton}
        >
          <Icons.ChevronLeft />
        </IconButton>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export default NavigationSecondary
