import { Box } from '@mui/material'

import { useTexts } from '../../../../texts'
import { Icons } from '../../../../assets'
import { MainPageContentLayout } from '../../../Layout'
import { ArticlesList, ARTICLES_DATE_TYPE, Tabs } from '../../../components'

const styles = {
  layout: {
    my: 0,
    mb: {
      xs: 2,
      md: 3,
    },
    mt: 0,
  },
  root: {
    flex: 1,
    mt: { md: 2 },
  },
}

const TAB_NAME = {
  READ: 'read',
  FAVORITE: 'favorite',
}

const LibraryView = ({
  activeTabName,
  onOpenArticle,
  onChangeTab,
  readArticlesData,
  favoriteArticlesData,
}) => {
  const texts = useTexts()

  const handleOpenReadArticle = (article) =>
    onOpenArticle(article, TAB_NAME.READ)
  const handleOpenFavoriteArticle = (article) =>
    onOpenArticle(article, TAB_NAME.FAVORITE)

  const TABS = [
    {
      name: TAB_NAME.READ,
      label: texts.read(),
      icon: <Icons.Eye />,
      content: (
        <ArticlesList
          name='read'
          onClickArticle={handleOpenReadArticle}
          dateType={ARTICLES_DATE_TYPE.READ}
          {...readArticlesData}
        />
      ),
    },
    {
      name: TAB_NAME.FAVORITE,
      label: texts.favorite(),
      icon: <Icons.Star />,
      content: (
        <ArticlesList
          name='favorite'
          onClickArticle={handleOpenFavoriteArticle}
          {...favoriteArticlesData}
        />
      ),
    },
  ]

  return (
    <MainPageContentLayout sx={styles.layout}>
      <Box sx={styles.root} id='library-page'>
        <Tabs
          ariaLabel={texts.getHomeTabsAriaLabel()}
          tabs={TABS}
          onClickTab={(_event, newTabIndex) =>
            onChangeTab(TABS[newTabIndex].name)
          }
          activeTabIndex={TABS.findIndex((tab) => tab.name === activeTabName)}
        />
      </Box>
    </MainPageContentLayout>
  )
}

export default LibraryView
