// docu on Displaying Apple Pay Buttons Using JS or CSS
//developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_javascript
// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_css

import { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'

import EVENTS_NAMES from '../../../../../../analytics/eventsNames.json'
import { ApplePay, User } from '../../../../../../business'
import { useTexts } from '../../../../../../texts'
import { TopUpSelectorCards, Alert } from '../../../../../components/index'
import styles from './applePayTopUp.styles'

const AMOUNT_OPTIONS = [1, 5, 10]
const POPULAR_OPTION = AMOUNT_OPTIONS[1]
const DEFAULT_OPTION = AMOUNT_OPTIONS[0]

const ApplePayTopUp = ({ onClickIntroBankCard, onContinue }) => {
  const texts = useTexts()

  const [selectedAmount, setSelectedAmount] = useState(DEFAULT_OPTION)
  const [errorMessage, setErrorMessage] = useState('')

  const handleGoToIntroBankCard = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PM_CLICK_GO_TO_REGISTER_BANKCARD,
        },
      }),
    )
    onClickIntroBankCard()
  }

  const handleTransactionSuccess = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PM_FORM_SUBMIT_TOPUP_APPLE,
          status: 'success',
          amount: selectedAmount,
        },
      }),
    )

    onContinue()
  }

  const handleTransactionError = (error) => {
    let errorMessage = texts.getApplePayTopUpError()

    if (error instanceof User.RedsysError) {
      const redsysErrorMessage = User.getRedsysDSResponseMessageTranslation(
        error.message,
      )
      if (Boolean(redsysErrorMessage)) errorMessage = redsysErrorMessage
    }

    setErrorMessage(errorMessage)

    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PM_FORM_SUBMIT_TOPUP_APPLE,
          status: 'error',
          formError: errorMessage,
          amount: selectedAmount,
        },
      }),
    )
  }

  const handleApplePayTransaction = async () => {
    setErrorMessage('')

    await ApplePay.handleTransaction(
      selectedAmount,
      handleTransactionSuccess,
      handleTransactionError,
    )
  }

  useEffect(() => {
    const onClickApplePayButton = async () => {
      setErrorMessage('')

      const fakeButton = document.getElementById('fake-apple-pay-button')
      fakeButton.click()
    }

    const applePayButton = document.querySelector('apple-pay-button')
    applePayButton.addEventListener('click', onClickApplePayButton)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={styles.root}>
      <Box sx={styles.form}>
        <TopUpSelectorCards
          sx={styles.topUpCards}
          options={AMOUNT_OPTIONS}
          popularOption={POPULAR_OPTION}
          selected={selectedAmount}
          setSelected={setSelectedAmount}
        />
        <Box sx={styles.actions}>
          {Boolean(errorMessage) && (
            <Alert variant='error' withContactLink text={errorMessage} />
          )}
          <div id='apple-pay-button-container'>
            <apple-pay-button
              buttonstyle='black'
              type='plain'
              locale='es-ES'
            ></apple-pay-button>
            <button
              id='fake-apple-pay-button'
              style={{ display: 'none' }}
              onClick={handleApplePayTransaction}
            />
          </div>
          <Button fullWidth onClick={handleGoToIntroBankCard}>
            {texts.getGoToIntroBankCardAction()}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ApplePayTopUp
