import { Box, Link as MUILink } from '@mui/material'

import { Session } from '../../../business'
import { useTexts } from '../../../texts'

import styles from './footer.styles'

const linkPropsForNewTab = {
  target: '_blank',
  rel: 'noopener',
}
const linkPropsForExternalLink = {
  target: '_blank',
  rel: 'noreferrer',
}

const PoliciesLinks = () => {
  const texts = useTexts()

  const isAuthenticated = Session.isAuthenticated()
  const linkPropsForInternalLink = !isAuthenticated ? linkPropsForNewTab : {}

  const LINKS = [
    {
      name: texts.getPrivacyPolicyLabel(),
      href: 'https://www.bepayper.com/politica-de-privacidad',
      props: linkPropsForExternalLink,
    },
    {
      name: texts.getServiceTermsLabel(),
      href: 'https://www.bepayper.com/terminos-y-condiciones',
      props: linkPropsForExternalLink,
    },
    {
      name: texts.getLegalNoticeLabel(),
      href: 'https://www.bepayper.com/aviso-legal',
      props: linkPropsForExternalLink,
    },
    {
      name: texts.getCookiesTitle(),
      href: 'https://www.bepayper.com/cookies',
      props: linkPropsForInternalLink,
    },
  ]

  return (
    <Box
      component='ul'
      sx={styles.policiesLinks}
      role='contentinfo'
      aria-label='Enlaces de Payper'
    >
      {LINKS.map((link, idx) => (
        <Box key={idx} component='li'>
          <MUILink href={link.href} variant='body1' {...link.props}>
            {link.name}
          </MUILink>
        </Box>
      ))}
    </Box>
  )
}

export default PoliciesLinks
