import { useState } from 'react'
import {
  InputAdornment,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import SingleInputForm from './SingleInputForm'
import styles from './searchBar.styles'

const SearchBar = ({ initialInputValue = '', onSubmit, onCancel }) => {
  const texts = useTexts()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const [searchInputValue, setSearchInputValue] = useState(initialInputValue)

  return (
    <Box sx={styles.searchBar}>
      {!!onCancel && (
        <IconButton
          aria-label={texts.backAriaLabel()}
          onClick={onCancel}
          sx={styles.iconButton}
        >
          <Icons.ArrowLeft />
        </IconButton>
      )}
      <SingleInputForm
        id='search-form'
        name='search'
        value={searchInputValue}
        onChangeValue={setSearchInputValue}
        placeholder={texts.searchPlaceholder()}
        onSubmit={onSubmit}
        startAdornment={
          widerThanTablet && (
            <InputAdornment position='start' disablePointerEvents>
              <Icons.Search color='#969594' width={20} height={20} />
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label={texts.clearAriaLabel()}
              onClick={() => setSearchInputValue('')}
              disabled={searchInputValue === ''}
            >
              <Icons.Close
                color={searchInputValue === '' ? '#969594' : '#343434'}
              />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default SearchBar
