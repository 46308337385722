import { Box, Typography, Button } from '@mui/material'
import { useMediaQuery } from '@mui/material'

import theme from '../../../theme'
import { ArticleCard } from '../ArticleCard'
import SkeletonsList from './SkeletonsList'
import { useTexts } from '../../../texts'
import styles from './articlesList.styles'

const ShowMoreButton = ({ onClick }) => {
  const texts = useTexts()
  return (
    <Box sx={styles.button}>
      <Button id='show-more' color='secondary' onClick={onClick} fullWidth>
        {texts.getShowMoreLabel()}
      </Button>
    </Box>
  )
}

const EmptyState = ({ text }) => (
  <Typography sx={{ my: 3, textAlign: 'center' }}>{text}</Typography>
)

const FeedbackOnFetch = ({ isFetching, error }) => {
  const texts = useTexts()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {isFetching ? (
        <Box sx={{ ...styles.articlesList, mt: 'var(--spacing-s)' }}>
          <SkeletonsList numberOfArticles={widerThanTablet ? 5 : 8} />
        </Box>
      ) : error ? (
        <EmptyState text={texts.getUnexpectedError()} />
      ) : null}
    </>
  )
}

const ArticlesList = ({
  isFetching,
  articles = [],
  isLastPage,
  error,
  fetchNewPage,
  onClickArticle,
  dateType,
  name,
}) => {
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        {articles.length === 0 ? (
          !isFetching && !error ? (
            <EmptyState text={texts.getNoResultsLabel()} />
          ) : (
            <FeedbackOnFetch isFetching={isFetching} error={error} />
          )
        ) : (
          <>
            <Box sx={styles.articlesList}>
              {articles.map((article, articleIndex, arr) => (
                <ArticleCard
                  article={article}
                  onClick={() => onClickArticle(article)}
                  dateType={dateType}
                  key={article.articleId}
                  id={
                    'article-' + (name ? name.concat('-') : '') + articleIndex
                  }
                />
              ))}
            </Box>
            {isFetching || error ? (
              <FeedbackOnFetch isFetching={isFetching} error={error} />
            ) : !isLastPage ? (
              <ShowMoreButton onClick={fetchNewPage} />
            ) : null}
          </>
        )}
      </Box>
    </Box>
  )
}

export default ArticlesList
