import { Box, useTheme, useMediaQuery } from '@mui/material'

import { SearchBar } from '../../../components'

const SEARCH_BAR_NODE_ID = 'search-bar'

const styles = {
  searchBarContainer: {
    backgroundColor: 'neutral.white',
    py: { xs: 1, md: 3 },
    px: { xs: 1.25, md: 0 },
    '& > div': {
      width: 'min(100%, var(--content-L-max-width))',
      mx: 'auto',
    },
  },
  fixed: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  results: {
    flexGrow: 2,
  },
}

const SearchView = ({
  initialInputValue,
  onCancelSearch,
  onSubmitSearch,
  renderResults,
}) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <Box
        sx={{
          ...styles.searchBarContainer,
          ...(!widerThanTablet ? styles.fixed : {}),
        }}
        id={SEARCH_BAR_NODE_ID}
      >
        <Box>
          <SearchBar
            initialInputValue={initialInputValue}
            onSubmit={onSubmitSearch}
            onCancel={onCancelSearch}
          />
        </Box>
      </Box>
      <Box sx={styles.results}>{renderResults()}</Box>
    </>
  )
}

export default SearchView
