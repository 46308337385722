import { createTheme } from '@mui/material/styles'
import * as dT from '../../styles/designTokens'

// NOTE: accessing to createBreakpoints is not recommended since it is a private module
// https://stackoverflow.com/questions/68554470/was-the-createbreakpoints-method-removed-in-material-version-5
const theme = createTheme()

const typography = {
  fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontFamily: dT.globals.typography.fontFamily.headings,
    fontSize: dT.sm.typography.typeScale.xxxl,
    fontWeight: dT.sm.typography.fontWeight.semiBold,
    letterSpacing: '0.0525rem',

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.xxxl,
      fontWeight: dT.lg.typography.fontWeight.semiBold,
      letterSpacing: '0.09rem',
    },
  },
  h2: {
    fontFamily: dT.globals.typography.fontFamily.headings,
    fontSize: dT.sm.typography.typeScale.xxl,
    fontWeight: dT.sm.typography.fontWeight.semiBold,
    letterSpacing: '0.04rem',

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.xxl,
      fontWeight: dT.lg.typography.fontWeight.semiBold,
      letterSpacing: '0.065rem',
    },
  },
  h3: {
    fontFamily: dT.globals.typography.fontFamily.headings,
    fontSize: dT.sm.typography.typeScale.xl,
    fontWeight: dT.sm.typography.fontWeight.semiBold,
    letterSpacing: '0.035rem',

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.xl,
      fontWeight: dT.lg.typography.fontWeight.semiBold,
      letterSpacing: '0.04rem',
    },
  },
  h4: {
    fontFamily: dT.globals.typography.fontFamily.headings,
    fontSize: dT.sm.typography.typeScale.l,
    fontWeight: dT.sm.typography.fontWeight.semiBold,
    letterSpacing: '0.025rem',

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.l,
      fontWeight: dT.lg.typography.fontWeight.semiBold,
      letterSpacing: '0.03rem',
    },
  },
  h5: {
    fontSize: dT.sm.typography.typeScale.l,
    fontWeight: dT.sm.typography.fontWeight.bold,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.l,
      fontWeight: dT.lg.typography.fontWeight.bold,
    },
  },
  h6: {
    fontSize: dT.sm.typography.typeScale.ml,
    fontWeight: dT.sm.typography.fontWeight.bold,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.ml,
      fontWeight: dT.lg.typography.fontWeight.bold,
    },
  },
  subtitle1: {
    fontSize: dT.sm.typography.typeScale.ml,
    fontWeight: dT.sm.typography.fontWeight.regular,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.ml,
      fontWeight: dT.lg.typography.fontWeight.regular,
    },
  },
  subtitle2: {
    fontSize: dT.sm.typography.typeScale.s,
    fontWeight: dT.sm.typography.fontWeight.semiBold,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.s,
      fontWeight: dT.lg.typography.fontWeight.semiBold,
    },
  },
  body1: {
    fontSize: dT.sm.typography.typeScale.m,
    fontWeight: dT.sm.typography.fontWeight.regular,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.m,
      fontWeight: dT.lg.typography.fontWeight.regular,
    },
  },
  button: {
    fontSize: dT.sm.typography.typeScale.m,
    fontWeight: dT.sm.typography.fontWeight.bold,
    letterSpacing: 0,
    textTransform: 'none',

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.m,
      fontWeight: dT.lg.typography.fontWeight.bold,
    },
  },
  cardHeading: {
    fontSize: dT.sm.typography.typeScale.ml,
    fontWeight: dT.sm.typography.fontWeight.regular,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.m,
      fontWeight: dT.lg.typography.fontWeight.regular,
    },
  },
  small: {
    fontSize: dT.sm.typography.typeScale.s,
    fontWeight: dT.sm.typography.fontWeight.regular,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.s,
      fontWeight: dT.lg.typography.fontWeight.regular,
    },
  },
  smallLabel: {
    fontSize: dT.sm.typography.typeScale.s,
    fontWeight: dT.sm.typography.fontWeight.bold,
    letterSpacing: 0,

    [theme.breakpoints.up('md')]: {
      fontSize: dT.lg.typography.typeScale.s,
      fontWeight: dT.lg.typography.fontWeight.bold,
    },
  },
}

export default typography
