import { Box, useTheme, useMediaQuery } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import PoliciesLinks from './PoliciesLinks'
import FormViewPrivate from './FormViewPrivate'
import FormViewPublic from './FormViewPublic'
import ResponseView from './ResponseView'
import Guarantors from './Guarantors'
import styles from './footer.styles'
import { VIEW } from './index'

const FooterView = ({ isPublic, mode, resetState, onSubmit, status, view }) => {
  const texts = useTexts()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const FormView = isPublic ? FormViewPublic : FormViewPrivate
  return (
    <Box
      component='footer'
      sx={{
        ...styles[mode],
        ...styles.root,
      }}
      aria-live='polite'
    >
      <Box sx={styles.container}>
        <Icons.PayperLogo
          height={isDesktop ? 28 : 24}
          width={undefined}
          aria-label='Payper logo'
          color={mode === 'dark' ? '#FFFFFF' : '#000000'}
        />

        <Guarantors />
        {view === VIEW.SUCCESS ? (
          <ResponseView
            title={texts.getContactFormSuccessTitleLabel()}
            message={texts.getContactFormSuccessDescriptionLabel()}
            ctaText={texts.getContactFormSendSuccessAction()}
            onClickCta={resetState}
          />
        ) : view === VIEW.ERROR ? (
          <ResponseView
            title={texts.getContactFormTitleError()}
            message={texts.getContactFormDescriptionError()}
            ctaText={texts.getContactFormSendSuccessAction()}
            onClickCta={resetState}
          />
        ) : (
          <FormView formId='contact-form' {...status} onSubmit={onSubmit} />
        )}
        <PoliciesLinks />
      </Box>
    </Box>
  )
}

export default FooterView
