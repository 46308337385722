import PropTypes from 'prop-types'
import { Typography, Box, Button } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import * as PT from '../../propTypes'
import { Chip, Alert } from '../index'
import styles from './creditCard.styles'

const BANK_CARD_TYPES = {
  EMPTY: 'empty',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

const creditCardViewPropTypes = {
  type: PropTypes.oneOf(Object.values(BANK_CARD_TYPES)),
  bankCard: PT.BankCard,
  onClickEditBankCard: PropTypes.func.isRequired,
  onClickAddBankCard: PropTypes.func.isRequired,
}

const CreditCardView = ({
  type,
  bankCard,
  onClickEditBankCard,
  onClickAddBankCard,
}) => {
  const texts = useTexts()

  const CHIP_BY_TYPE = {
    [BANK_CARD_TYPES.EMPTY]: {
      text: texts.getBankCardNotRegisteredLabel(),
      variant: 'error',
    },
    [BANK_CARD_TYPES.INACTIVE]: {
      text: texts.getBankCardOutdatedLabel(),
      variant: 'error',
    },
    [BANK_CARD_TYPES.ACTIVE]: {
      text: texts.getBankCardUpdatedLabel(),
      variant: 'success',
    },
  }

  const ACTION_BY_TYPE = {
    [BANK_CARD_TYPES.EMPTY]: {
      label: texts.getBankCardIntroAction(),
      onClick: onClickAddBankCard,
      buttonProps: { color: 'secondary' },
    },
    [BANK_CARD_TYPES.INACTIVE]: {
      label: texts.getBankCardAddLabel(),
      onClick: onClickEditBankCard,
      buttonProps: { color: 'secondary' },
    },
    [BANK_CARD_TYPES.ACTIVE]: {
      label: texts.getBankCardEditAction(),
      onClick: onClickEditBankCard,
      buttonProps: { variant: 'outlined' },
    },
  }

  const action = ACTION_BY_TYPE[type]

  return (
    <Box
      sx={(theme) => ({
        ...styles.root(theme),
        ...(type !== BANK_CARD_TYPES.ACTIVE ? styles.error(theme) : {}),
      })}
    >
      <Box sx={styles.header}>
        <Chip {...CHIP_BY_TYPE[type]} />
        <Icons.Contactless />
      </Box>

      {type === BANK_CARD_TYPES.ACTIVE ? (
        <Box sx={styles.bankCardNumber}>
          <Typography component='span' sx={styles.cardData}>
            {`•••• •••• •••• ${bankCard.cardNumber?.slice(-4)}`}
          </Typography>
          <Typography component='span' sx={styles.cardData}>
            {bankCard.expiryDate}
          </Typography>
        </Box>
      ) : type === BANK_CARD_TYPES.INACTIVE ? (
        <Alert variant='error' text={texts.invalidBankCardWarning()} />
      ) : type === BANK_CARD_TYPES.EMPTY ? (
        <Alert variant='info' text={texts.emptyBankCardWarning()} />
      ) : null}

      <Button fullWidth onClick={action.onClick} {...action.buttonProps}>
        {action.label}
      </Button>
    </Box>
  )
}
CreditCardView.propTypes = creditCardViewPropTypes

const creditCardPropTypes = {
  bankCard: PT.BankCard,
  onClickEditBankCard: PropTypes.func.isRequired,
  onClickAddBankCard: PropTypes.func.isRequired,
}

const CreditCard = (props) => {
  const type = !Boolean(props.bankCard)
    ? BANK_CARD_TYPES.EMPTY
    : props.bankCard.isExpired
    ? BANK_CARD_TYPES.INACTIVE
    : BANK_CARD_TYPES.ACTIVE

  return <CreditCardView {...props} type={type} />
}
CreditCard.propTypes = creditCardPropTypes

export default CreditCard
