import { useEffect, useState } from 'react'
import { User, Public } from '../../../business'
import View from './View'

export const VIEW = {
  FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
}

const Footer = ({ isPublic }) => {
  const useContactForm = isPublic ? Public.useContactForm : User.useContactForm
  const FORM_ID = isPublic ? 'public-contact-form' : 'private-contact-form'
  const { status, isSubmitSuccess, submitForm, resetState } =
    useContactForm(FORM_ID)

  const [isSended, setIsSended] = useState(false)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  useEffect(() => {
    if (isSubmitSuccess() || Boolean(status.formError)) {
      setIsSended(true)
    } else {
      setIsSended(false)
    }
  }, [status.formError, isSubmitSuccess])

  return (
    <View
      isPublic={isPublic}
      mode={isSended ? 'light' : 'dark'}
      view={
        isSubmitSuccess()
          ? VIEW.SUCCESS
          : Boolean(status.formError)
          ? VIEW.ERROR
          : VIEW.FORM
      }
      status={status}
      resetState={resetState}
      onSubmit={handleSubmit}
    />
  )
}

export default Footer
