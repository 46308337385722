import { Box, Typography } from '@mui/material'

import { formatFullLongDate, formatPrice } from '../../format'
import { useTexts } from '../../../texts'
import styles from './articleCard.styles'
import { ARTICLES_DATE_TYPE } from './index'

const formattedPrice = (price) =>
  price === undefined ? '' : formatPrice(price)

const MetaInfoDetails = ({ article, dateType, showPriceLabel }) => {
  const texts = useTexts()

  const dateLabel =
    dateType === ARTICLES_DATE_TYPE.PUBLISHED
      ? texts.publishedDate()
      : texts.readDate()

  const priceLabel =
    article.isPremium === undefined
      ? null
      : article.isPremium === 0
      ? texts.free()
      : formattedPrice(article?.mediaArticlePrice)

  return (
    <Box sx={styles.detail}>
      {Boolean(article?.date) && (
        <Typography variant='small'>
          <b>{dateLabel}: </b>
          {formatFullLongDate(article.date)}
        </Typography>
      )}
      {showPriceLabel && Boolean(priceLabel) && (
        <>
          <div className='separator'></div>
          <Typography
            variant='small'
            sx={article.isPremium === 0 && styles.freeLabel}
          >
            {priceLabel}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default MetaInfoDetails
