import PropTypes from 'prop-types'
import ArticleCardSkeleton from '../ArticleCard/SkeletonCard'

const articlesSkeletonPropTypes = {
  numberOfArticles: PropTypes.number,
}

const ArticlesSkeleton = (props) => (
  <>
    {[...Array(props.numberOfArticles).keys()].map((n) => (
      <ArticleCardSkeleton key={n} />
    ))}
  </>
)

ArticlesSkeleton.propTypes = articlesSkeletonPropTypes

export default ArticlesSkeleton
