const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: {
      xs: 2,
      md: 2.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: {
      xs: 2.5,
      md: 3.5,
    },
    px: {
      xs: 1.25,
      md: 2.5,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
}

export default styles
