import { useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

const headerHeight = () =>
  document.querySelector('header')?.getBoundingClientRect()?.height

const ScrollRevisionOnPageChange = ({ children }) => {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    if (navigationType !== 'POP' && !Boolean(location.hash)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    if (Boolean(location.hash)) {
      const id = location.hash.slice(1)
      const elementToScrollTo = document.getElementById(id)

      if (elementToScrollTo) {
        const { top } = elementToScrollTo.getBoundingClientRect()
        const scrollTop = window.scrollY || document.documentElement.scrollTop

        const scrollToY = top + scrollTop - headerHeight() - 20
        window.scrollTo({
          top: scrollToY,
          behavior: 'smooth',
        })
      }
    }
  }, [location, navigationType])

  return <>{children}</>
}

export default ScrollRevisionOnPageChange
