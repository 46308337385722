const styles = {
  root: (theme) => ({
    '--card-width': { xs: '21.4375rem', md: '30rem' },
    width: { xs: 'min(100%, var(--card-width))', md: 'var(--card-width)' },
    minHeight: '9.75rem',

    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-l)',
    p: 'var(--spacing-l)',
    borderRadius: 'var(--corners-l)',
    boxShadow: 2,

    color: 'neutral.darkest',
    backgroundColor: 'neutral.white',
    '--icon-color': theme.palette.neutral.main,

    '& button': {
      maxWidth: '100%',
    },
  }),
  error: (theme) => ({
    '--icon-color': theme.palette.error.main,
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '& svg': {
      height: 'var(--size-iconXL)',
      width: 'var(--size-iconXL)',
      color: 'var(--icon-color)',
    },
  },
  bankCardNumber: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default styles
