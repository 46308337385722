import baseTheme from '../base'

const MuiLoadingButton = {
  defaultProps: {
    color: 'inherit',
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      ...baseTheme.typography.button,
    },
  },
}

export default MuiLoadingButton
