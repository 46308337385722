import { createContext, useContext, useState, useEffect } from 'react'
import { getArticlesSections as getArticlesSectionsService } from './service'

const ArticlesSectionsContext = createContext(null)

export const useArticlesSections = () => {
  const context = useContext(ArticlesSectionsContext)
  if (context === null) {
    throw new Error(
      'useArticlesSections must be used within a ArticlesSectionsProvider',
    )
  }
  return context
}

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const ArticlesSectionsProvider = ({ children }) => {
  const [state, setState] = useState(initialState)

  const fetchArticlesSections = async () => {
    try {
      const sections = await getArticlesSectionsService()
      setState({
        isLoading: false,
        data: sections,
        error: false,
      })
    } catch (error) {
      setState({ isLoading: false, data: null, error })
    }
  }

  useEffect(() => {
    ;(async () => await fetchArticlesSections())()
  }, [])

  return (
    <ArticlesSectionsContext.Provider value={state}>
      {children}
    </ArticlesSectionsContext.Provider>
  )
}

export const ArticlesSectionsProviderMock = ({ children }) => {
  const STATE = {
    isLoading: false,
    data: {
      ciencia: 'Ciencia',
      cultura: 'Cultura',
      deportes: 'Deportes',
      economia: 'Economía',
      entretenimiento: 'Ocio',
      españa: 'España',
      internacional: 'Internacional',
      opinion: 'Opinión',
      regional: 'Regional',
      sociedad: 'Sociedad',
    },
    error: false,
  }

  return (
    <ArticlesSectionsContext.Provider value={STATE}>
      {children}
    </ArticlesSectionsContext.Provider>
  )
}

export default ArticlesSectionsProvider
