import baseTheme from '../base'

const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      marginLeft: 0,
    },
    label: {
      '& .MuiLink-root': {
        fontWeight: 600,
        color: `var(--link-color), ${baseTheme.palette.neutral.darkest}`,

        '&:hover': {
          color: baseTheme.palette.neutral.main,
          cursor: 'pointer',
        },
      },
    },
  },
}

export default MuiFormControlLabel
