import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { SearchBar } from '../../../components'

const styles = {
  root: {
    px: { xs: 1.25, md: 0 },
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
  },
}
const Search = () => {
  const navigate = useNavigate()

  const navigateToSearch = (ev) => {
    ev.preventDefault()
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_SEARCH,
          text: ev.target[0].value,
        },
      }),
    )

    if (Boolean(ev.target[0].value)) {
      navigate(`search/${encodeURIComponent(ev.target[0].value)}`)
    }
  }

  return (
    <Box sx={styles.root}>
      <SearchBar onSubmit={navigateToSearch} />
    </Box>
  )
}

export default Search
