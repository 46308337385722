import { useNavigate, useLocation } from 'react-router-dom'

import { User } from '../../../business'
import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import { NAVIGATION_ITEMS, AUTHENTICATED_DEFAULT_ROUTE } from '../../constants'
import { Avatar } from '../../components'

const usePrivateNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const texts = useTexts()

  const {
    state: { data: user },
  } = User.usePersonalData()

  const getUserName = (name) =>
    name !== undefined && name !== '.' ? name : texts.myAccount()

  const actions = [
    {
      name: NAVIGATION_ITEMS.DISCOVERY,
      icon: <Icons.Search height='24px' width='24px' />,
      label: texts.discovery(),
      path: '/discovery',
    },
    {
      name: NAVIGATION_ITEMS.LIBRARY,
      icon: <Icons.Library height='24px' width='24px' />,
      label: texts.library(),
      path: '/library/read',
    },
    {
      name: NAVIGATION_ITEMS.ACCOUNT,
      icon: <Avatar user={user} size={24} />,
      label: getUserName(user?.name),
      path: '/account',
    },
  ]

  const handleNavigation = (menuItem) => {
    navigate(menuItem.path)
  }
  const handleGoHome = () => {
    navigate(AUTHENTICATED_DEFAULT_ROUTE)
  }

  const getActiveAction = () => {
    const active = actions.find((action) =>
      location.pathname.includes(action.path),
    )
    return !!active ? active.name : null
  }

  return {
    actions,
    handleNavigation,
    handleGoHome,
    getActiveAction,
  }
}

export default usePrivateNavigation
