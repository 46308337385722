import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme, useMediaQuery } from '@mui/material'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { Chip } from '../../../components'
import { PageBlock } from '../../../Layout'
import { useTexts } from '../../../../texts'

const styles = {
  buttonsContainer: {
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    p: 0,

    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: 0.5, md: 1 },
  },
}

const removeEmojiWithSpace = (str) => str.replace(/[\p{Emoji}]\s+/gu, '')

const sanitizeTopic = (str) => removeEmojiWithSpace(str).toLowerCase()

const splitEmojiMarkerInText = (str) => {
  let text = str
  let emoji = ''

  // NOTE: regex to handle compound emojis
  const initialEmojiRegex = /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F?)\s+/u
  const emojiMatches = str.match(initialEmojiRegex)

  if (Boolean(emojiMatches?.length)) {
    emoji = emojiMatches[0]
    text = str.replace(emoji, '')
  }
  return [emoji, text]
}

const Interests = ({ partnerData }) => {
  const navigate = useNavigate()
  const interests = partnerData?.data?.configuration?.interests

  const handleClickTopic = (topic) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_TOPIC_OF_INTEREST_CLICK,
          topic: topic.label,
        },
      }),
    )
    navigate(`topic/${topic.key}`, {
      state: { title: topic.label },
    })
  }

  if (partnerData.isLoading || !Boolean(interests)) {
    return null
  }
  return <View interests={interests} onClickTopic={handleClickTopic} />
}

const View = ({ interests, onClickTopic }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const texts = useTexts()
  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_TOPIC_OF_INTEREST_SHOW,
        },
      }),
    )
  }, [])

  return (
    <PageBlock title={texts.getInterestsTitle()}>
      <Box sx={styles.buttonsContainer} id='section_buttons'>
        {interests.map((topicText) => {
          const topic = {
            key: encodeURIComponent(sanitizeTopic(topicText)),
            label: topicText,
          }

          return (
            <Chip
              key={topic.key}
              text={
                isDesktop ? topic.label : splitEmojiMarkerInText(topic.label)[1]
              }
              variant='inverted'
              onClick={() => onClickTopic(topic)}
            />
          )
        })}
      </Box>
    </PageBlock>
  )
}

export default Interests
