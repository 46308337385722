import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { Alert, FormSkinWrapper, TextField } from '../../../../components'
import { Icons } from '../../../../../assets'

import { useTexts } from '../../../../../texts'
import styles from '../access.styles'

const passwordStepViewPropTypes = {
  email: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onGoToForgottenPassword: PropTypes.func.isRequired,
}

const PasswordStepView = ({
  email,
  formId,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  onGoToForgottenPassword,
}) => {
  const texts = useTexts()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <Typography id='dialogTitle' sx={styles.heading}>
        {texts.getLoginTitle()}
      </Typography>
      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.form}>
          <TextField
            id='email'
            name='email'
            defaultValue={email}
            label={texts.getLoginFormNameLabel()}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <Icons.Envelope />
                </InputAdornment>
              ),
            }}
            inputProps={{ inputMode: 'email' }}
            required
          />
          <TextField
            id='password'
            name='password'
            label={texts.getLoginFormPasswordLabel()}
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            helperText={fieldErrors.password}
            error={Boolean(fieldErrors.password)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icons.LockClosed />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position='end'
                  onClick={() =>
                    setShowPassword((showPassword) => !showPassword)
                  }
                >
                  <IconButton aria-label={texts.getTogglePasswordAction()}>
                    {showPassword ? <Icons.EyeFilled /> : <Icons.EyeClosed />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />

          {Boolean(formError) && <Alert variant='error' text={formError} />}
          <LoadingButton
            color='secondary'
            type='submit'
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
            sx={styles.ctaBicolorLabel}
            endIcon={<Icons.ArrowRight />}
          >
            {texts.getLoginFormSubmitAction()}
          </LoadingButton>
          <Button
            id='forgotten-password-btn'
            variant='text'
            onClick={onGoToForgottenPassword}
            fullWidth
            sx={{ mt: 1 }}
          >
            {texts.getForgottenPasswordAction()}
          </Button>
        </FormSkinWrapper>
      </form>
    </>
  )
}

PasswordStepView.propTypes = passwordStepViewPropTypes

export default PasswordStepView
