import { Box } from '@mui/material'

const styles = {
  backgroundColor: 'neutral.white',

  '& > div': {
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: 2,
      md: 3,
    },
  },
}

const WhiteBckgFullWidth = ({ children, sx = {} }) => {
  return (
    <Box sx={{ ...styles, ...sx }} className='full-bleed'>
      <Box>{children}</Box>
    </Box>
  )
}

export default WhiteBckgFullWidth
