import { Card, Box, Skeleton } from '@mui/material'

import styles from './articleCard.styles'

const SkeletonCard = () => (
  <Box sx={styles.root} variant='plain'>
    <Card variant='plain' sx={styles.card}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton height={16} width={90} />
        <Skeleton height={90} />
        <Skeleton height={16} width={130} />
      </Box>
      <Skeleton
        variant='rectangular'
        height={'var(--image-size)'}
        width={'var(--image-size)'}
      />
    </Card>
  </Box>
)

export default SkeletonCard
