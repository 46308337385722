import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import styles from './chip.styles.js'

const chipPropTypes = {
  variant: PropTypes.oneOf([
    'default',
    'inverted',
    'info',
    'error',
    'success',
    'warning',
  ]),
}

const Chip = ({ variant = 'default', text, onClick }) => (
  <Typography
    component={onClick ? 'a' : 'span'}
    onClick={onClick}
    sx={{
      ...styles[variant],
      ...styles.chip,
      ...(Boolean(onClick) ? styles.clickable : {}),
    }}
  >
    {text}
  </Typography>
)

Chip.propTypes = chipPropTypes

export default Chip
