import { useState, useEffect } from 'react'

import { Public } from '..'

const BASE_PAGINATION = {
  limit: 20,
}
const INITIAL_PAGE = 1

const getArticlesFullData = (articles, mediasData) => {
  if (!articles.length || mediasData.isLoading) {
    return articles
  }
  return articles.map((article) => {
    const media = mediasData.data.find((m) => m.id === article.mediaProviderId)
    if (!media) return article
    return {
      ...article,
      mediaName: media.name,
      mediaIconUrl: media.iconSvgUrl,
      mediaImage: media.imageUrl,
      mediaArticlePrice: media.articlePrice,
    }
  })
}

// NOTE: this 'id' param is used as a way to force a re-start of the hook
const useArticlesList = (
  { fetchFn, withLastPageValidationHack = false },
  id,
) => {
  const mediasData = Public.useMediaProviders()

  const [lastFetchedPage, setLastFetchedPage] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [articles, setArticles] = useState([])
  const [isLastPage, setIsLastPage] = useState(false)
  const [error, setError] = useState(false)

  const validateIsLastPage = (resp) => {
    const { pagination, totalCount, data } = resp
    if (withLastPageValidationHack) {
      if (data.length < pagination.limit) {
        setIsLastPage(true)
      }
    } else if (totalCount === undefined) return

    if (totalCount - pagination.offset <= pagination.limit) {
      setIsLastPage(true)
    }
  }

  // NOTE: the value of the page argument must be a number equal to lastFetchedPage or (lastFetchedPage +1)
  const fetchPage = async (page) => {
    if (!isFetching) {
      try {
        setError(false)
        setIsFetching(true)

        let pagination = {
          ...BASE_PAGINATION,
          offset: (page - 1) * BASE_PAGINATION.limit,
        }
        if (withLastPageValidationHack) {
          pagination = { ...pagination, limit: pagination.limit + 1 }
        }

        const articlesResp = await fetchFn(pagination)
        if (!articlesResp) throw error
        validateIsLastPage(articlesResp)

        const { data: articlesRespData } = articlesResp
        if (
          withLastPageValidationHack &&
          articlesRespData.length === articlesResp.pagination.limit
        ) {
          articlesRespData.pop()
        }

        const newArticlesFullData = getArticlesFullData(
          articlesRespData,
          mediasData,
        )

        if (page === INITIAL_PAGE) {
          setArticles(newArticlesFullData)
          setLastFetchedPage(page)
          // NOTE: DOUBLE-CHECK FOR NOT ADDING THE SAME RESPONSE TWICE
        } else if (page !== lastFetchedPage) {
          setArticles(articles.concat(newArticlesFullData))
          setLastFetchedPage(page)
        }
      } catch (error) {
        setError(true)
      }
      setIsFetching(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchPage(INITIAL_PAGE)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediasData, fetchFn])

  return {
    isFetching,
    articles: getArticlesFullData(articles, mediasData),
    isLastPage,
    error,
    fetchNewPage: () => fetchPage(lastFetchedPage + 1),
  }
}

export default useArticlesList
