import baseTheme from '../base'

const MuiTab = {
  styleOverrides: {
    root: {
      borderRadius: baseTheme.spacing(6.25),
      color: baseTheme.palette.neutral.black,
      backgroundColor: baseTheme.palette.neutral.mainLight,

      padding: `${baseTheme.spacing(0.75)} ${baseTheme.spacing(1.5)}`,
      minHeight: baseTheme.spacing(2.625),
      [baseTheme.breakpoints.up('md')]: {
        padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(2)}`,
        minHeight: baseTheme.spacing(3.25),
      },

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral.main,
      },
      '&:not(:hover, .Mui-selected) > svg': {
        color: baseTheme.palette.neutral.main,
      },

      '&.Mui-selected': {
        color: baseTheme.palette.neutral.white,
        backgroundColor: baseTheme.palette.neutral.black,
        '&:hover': {
          backgroundColor: baseTheme.palette.neutral.darkest,
        },
      },

      '& .MuiTab-iconWrapper': {
        marginRight: baseTheme.spacing(0.5),
      },
    },
  },
}

export default MuiTab
