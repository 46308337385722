import * as designTokens from './designTokens'

// type CSSValue = string | number

// interface JsonObject {
//   [key: string]: CSSValue |JsonObject
// }

const jsonToCssVars = (jsonObj, prefix = '') => {
  let cssVariables = ''

  for (const key in jsonObj) {
    if (typeof jsonObj[key] === 'object' && jsonObj[key] !== null) {
      cssVariables += jsonToCssVars(
        jsonObj[key],
        Boolean(prefix) ? `${prefix}-${key}` : key,
      )
    } else {
      const variableName = `--${prefix}-${key}`
      cssVariables += `${variableName}: ${jsonObj[key]};\n`
    }
  }

  return cssVariables
}

const WIDTH_BREAKPOINT = 600

const designTokensCssVarsDeclaration = `
  :root {
    /* Global vars - by the moment they're not used (defined in MUI theme)
    ${jsonToCssVars(designTokens.globals)}  */

    /* Media query for larger screens */
    @media (max-width: ${WIDTH_BREAKPOINT}px) {
      ${jsonToCssVars(designTokens.sm)}
    }

    /* Media query for smaller screens */
    @media (min-width: ${WIDTH_BREAKPOINT + 1}px) {
      ${jsonToCssVars(designTokens.lg)}
    }
  }
`

export const createDesignTokensCssVars = () => {
  const style = document.createElement('style')
  style.textContent = designTokensCssVarsDeclaration
  document.head.appendChild(style)
}
