const appBarHeightRem = 4.5

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 4,
    backgroundColor: 'primary.main',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    height: (theme) => theme.spacing(appBarHeightRem),
  },
  toolbar: {
    width: 'min(100%, var(--container-max-width))',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ml: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
    mr: {
      xs: 0,
      md: 1,
      lg: 2,
    },
  },
  wrapLogo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
  },
  logo: {
    display: 'flex',
    width: 'auto',
    backgroundColor: 'transparent',

    '&.MuiButton-root': {
      p: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },

  menuList: {
    display: 'flex',
    columnGap: 1.5,

    '& .MuiMenuItem-root': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      columnGap: 0.5,
      p: 0.5,

      '&:hover, &:focus-visible': {
        backgroundColor: 'primary.dark',
        borderRadius: (theme) => theme.spacing(0.5),
      },

      '& .MuiButton-endIcon': {
        ml: 0,
        '& :nth-of-type(1)': {
          fontSize: 16,
        },
      },
    },

    '& .Mui-selected > .MuiListItemText-root > *': {
      fontWeight: 700,
    },
  },
  menuButton: {
    py: {
      xs: 0.5,
      md: 0.625,
    },
    px: {
      xs: 0.75,
      md: 2,
    },
    borderRadius: (theme) => theme.spacing(0.5),
    fontSize: (theme) => ({
      xs: theme.spacing(0.875),
      md: theme.spacing(1),
    }),
    whiteSpace: 'nowrap',
  },
}

export default styles
