import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../texts'
import { TextField } from '../index'
import { Icons } from '../../../assets'

import styles from './footer.styles'

const contactFormProptypes = {
  formId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
}

const ContactFormView = ({ formId, isSubmitting, fieldErrors, onSubmit }) => {
  const texts = useTexts()

  return (
    <Box
      component='form'
      id={formId}
      onSubmit={onSubmit}
      noValidate
      sx={styles.view}
    >
      <Typography component='h4'>{texts.getContactFormTitleLabel()}</Typography>
      <TextField
        id='contactFormMessage'
        name='message'
        label={texts.getContactFormMessageLabel()}
        multiline
        rows={6}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icons.ChatTeardropText />
            </InputAdornment>
          ),
        }}
        error={Boolean(fieldErrors.message)}
        helperText={fieldErrors.message}
        required
      />
      <LoadingButton
        type='submit'
        loading={isSubmitting}
        disabled={isSubmitting}
        fullWidth
        endIcon={<Icons.ArrowRight />}
        loadingIndicator={<CircularProgress size={16} />}
      >
        {texts.getContactFormSendAction()}
      </LoadingButton>
    </Box>
  )
}

ContactFormView.propTypes = contactFormProptypes

export default ContactFormView
