const DEFAULT_PATH = '/'
const DEFAULT_DAYS_TO_EXPIRATION = 30 * 3
export const DEFAULT_COOKIE_CONFIG = {
  daysToExpire: DEFAULT_DAYS_TO_EXPIRATION,
  path: DEFAULT_PATH,
}

export const ENDLESS_COOKIE_CONFIG = { path: DEFAULT_PATH }

export const setCookie = (name, value, config = DEFAULT_COOKIE_CONFIG) => {
  let pathAttribute = ''
  if (config?.path) {
    pathAttribute = `; path=${config.path}`
  }

  let expiresAttribute = ''
  if (config?.daysToExpire) {
    const date = new Date()
    date.setTime(date.getTime() + config.daysToExpire * 24 * 60 * 60 * 1000)
    expiresAttribute = '; expires=' + date.toUTCString()
  }

  let maxAgeAttribute = ''
  if (config?.maxAgeInSeconds) {
    maxAgeAttribute = `; max-age=${config.maxAgeInSeconds}`
  }

  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    maxAgeAttribute +
    expiresAttribute +
    pathAttribute
}

export const getCookie = (cookieName) =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1]
