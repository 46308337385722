import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import image from '../../../assets/images/ministerio_cultura.png'
import styles from './footer.styles'

const Guarantors = () => {
  const texts = useTexts()
  return (
    <Box sx={styles.guarantorsView}>
      <Typography>{texts.getGuarantorsLabel()}</Typography>
      <Box sx={styles.guarantorLogo}>
        <img src={image} alt='Ministerio de Cultura del Gobierno de España' />
      </Box>
    </Box>
  )
}

export default Guarantors
