const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'neutral.lightest',
  },
  main: {
    height: '100%',
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: {
      xs: 2.5,
      md: 3.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(59.5)})`,
    mx: 'auto',
    my: {
      xs: 2,
      md: 3,
    },

    '& h2': {
      textAlign: {
        md: 'center',
      },
    },
  },
}

export default styles
