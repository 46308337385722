import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
  Link as MUILink,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../texts'
import { TextField, Checkbox } from '../index'
import { Icons } from '../../../assets'

import styles from './footer.styles'

const contactFormProptypes = {
  formId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
}

const ContactFormView = ({ formId, isSubmitting, fieldErrors, onSubmit }) => {
  const texts = useTexts()

  return (
    <Box
      component='form'
      id={formId}
      onSubmit={onSubmit}
      noValidate
      sx={styles.view}
    >
      <Typography component='h4'>{texts.getContactFormTitleLabel()}</Typography>

      <Box sx={styles.groupFields}>
        {/*
          NOTE: According to UI specs, we handle both errors in one
          place, so get rid of the built-in prop `helperText` the `TextField`
          component provides.
        */}
        <TextField
          id='contactFormName'
          name='name'
          label={texts.getContactFormNameLabel()}
          autoComplete='given-name'
          error={Boolean(fieldErrors.name)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icons.User2 />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          id='contactFormFamilyName'
          name='familyName'
          label={texts.getContactFormSurnameLabel()}
          autoComplete='family-name'
          error={Boolean(fieldErrors.familyName)}
          Icon={null}
          required
        />
        {fieldErrors.name || fieldErrors.familyName ? (
          <Box sx={styles.groupErrors}>
            <Icons.ExclamationTriangle />
            {fieldErrors.name || fieldErrors.familyName}
          </Box>
        ) : null}
      </Box>
      <TextField
        id='contactFormEmail'
        name='email'
        label={texts.getContactFormEmailLabel()}
        type='email'
        autoComplete='email'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icons.Envelope />
            </InputAdornment>
          ),
        }}
        error={Boolean(fieldErrors.email)}
        helperText={fieldErrors.email}
        inputProps={{ inputMode: 'email' }}
        required
      />
      <TextField
        id='contactFormMessage'
        name='message'
        label={texts.getContactFormMessageLabel()}
        multiline
        rows={6}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icons.ChatTeardropText />
            </InputAdornment>
          ),
        }}
        error={Boolean(fieldErrors.message)}
        helperText={fieldErrors.message}
        required
      />
      <Checkbox
        skin='dark'
        label={
          <>
            {texts.getLegalAgreementLabel1()}
            <MUILink
              href='https://www.bepayper.com/terminos-y-condiciones'
              target='_blank'
              rel='noreferrer'
              variant='inherit'
            >
              {texts.getLegalAgreementTermsLabel()}
            </MUILink>
            {texts.getAndLabel()}
            <MUILink
              href='https://www.bepayper.com/politica-de-privacidad'
              target='_blank'
              rel='noreferrer'
              variant='inherit'
            >
              {texts.getLegalAgreementPrivacyPolicyLabel()}
            </MUILink>
            {texts.getLegalAgreementLabel2()}
          </>
        }
        name='conditionsAccepted'
        error={
          fieldErrors.conditionsAccepted && texts.getErrorConditionsAccepted()
        }
        required
      />
      <Checkbox
        sx={{ mt: -0.5 }}
        skin='dark'
        label={texts.getLegalAgreementCommunicationsLabel()}
        name='communicationsAccepted'
        error={fieldErrors.communicationsAccepted}
      />

      <LoadingButton
        type='submit'
        loading={isSubmitting}
        disabled={isSubmitting}
        fullWidth
        endIcon={<Icons.ArrowRight />}
        loadingIndicator={<CircularProgress size={16} />}
      >
        {texts.getContactFormSendAction()}
      </LoadingButton>
    </Box>
  )
}

ContactFormView.propTypes = contactFormProptypes

export default ContactFormView
