import { useTexts } from '../../../../texts'
import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { User, useFetch } from '../../../../business'
import { AssociatedMedia } from '../../../components'
import { openMediaLink } from '../../../utils'
import { ASSOCIATED_MEDIAS_DATA } from '../../../constants'
import { PageContent, PageBlock, WhiteBckgFullWidth } from '../../../Layout'
import { PAGE_CONTENT_MARGINS } from '../../../Layout/PageContent'
import Search from './Search'
import Sections from './Sections'
import AddToHomeScreenWizard from './AddToHomeScreenWizard'
import HeroPartner from './HeroPartner'
import Interests from './Interests'

const useActivePartner = () => useFetch(User.fetchActivePartner)

const Discovery = () => {
  const partnerData = useActivePartner()

  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
        },
      }),
    )

    openMediaLink(mediaData.domain, document.location.pathname)
  }

  return (
    <View
      partnerData={partnerData}
      medias={ASSOCIATED_MEDIAS_DATA}
      onClickMedia={openMediaPage}
    />
  )
}

export const View = ({
  partnerData,
  medias,
  onClickMedia,
  withoutAddToHomeWizard,
}) => {
  const texts = useTexts()
  return (
    <PageContent sx={{ mt: 0 }}>
      <WhiteBckgFullWidth sx={{ py: PAGE_CONTENT_MARGINS.mt }}>
        <HeroPartner partnerData={partnerData} />
        <Search />
      </WhiteBckgFullWidth>
      <Interests partnerData={partnerData} />
      <PageBlock title={texts.getAssociatedMediaPublicTitle()}>
        <AssociatedMedia medias={medias} onClickMedia={onClickMedia} />
      </PageBlock>
      {!withoutAddToHomeWizard && <AddToHomeScreenWizard />}
      <Sections />
    </PageContent>
  )
}

export default Discovery
