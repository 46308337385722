import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts'
import { Icons } from '../../../../assets'
import {
  Alert,
  BankCard,
  CircularProgress,
  TopUpSelectorCards,
} from '../../../components'
import styles from './topUpBalance.styles'

const AMOUNT_OPTIONS = [1, 5, 10]
const POPULAR_OPTION = AMOUNT_OPTIONS[1]
const DEFAULT_OPTION = AMOUNT_OPTIONS[0]

const topUpBalancePropTypes = {
  bankCardData: PropTypes.shape({
    cardNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    isExpired: PropTypes.bool,
  }),
  onClickTopUp: PropTypes.func,
  isRequestingUpdate: PropTypes.bool,
  messageErrorOnRequest: PropTypes.string,
}

const View = ({
  bankCardData,
  onClickTopUp,
  isRequestingUpdate,
  messageErrorOnRequest,
}) => {
  const texts = useTexts()
  const [selected, setSelected] = useState(DEFAULT_OPTION)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    onClickTopUp(selected)
  }

  return (
    <Box component='ol' sx={styles.root}>
      <Box component='li' sx={styles.panel}>
        <Box sx={styles.wrapper}>
          <Typography variant='subtitle1' sx={styles.heading}>
            {texts.getBalanceTopUpQuantityLabel()}
          </Typography>
          <form id='amount' onSubmit={handleSubmit}>
            <TopUpSelectorCards
              options={AMOUNT_OPTIONS}
              popularOption={POPULAR_OPTION}
              selected={selected}
              setSelected={setSelected}
            />
          </form>
        </Box>
      </Box>

      <Box component='li' sx={{ ...styles.cardPanel, ...styles.panel }}>
        <Box sx={styles.wrapper}>
          <Typography variant='subtitle1' sx={styles.heading}>
            {texts.getBankCardYourLabel()}
          </Typography>

          <Box sx={styles.action}>
            {bankCardData ? (
              <BankCard bankCardData={bankCardData} />
            ) : (
              <Box
                sx={{
                  height: 300,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {Boolean(messageErrorOnRequest) && (
              <Alert
                variant='error'
                withContactLink
                text={messageErrorOnRequest}
              />
            )}

            <LoadingButton
              color='secondary'
              startIcon={<Icons.Coins1 />}
              loading={isRequestingUpdate}
              type='submit'
              form='amount'
              disabled={Boolean(messageErrorOnRequest) && !isRequestingUpdate}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: texts.getBalanceTopUpAmountAction(selected),
                }}
              />
            </LoadingButton>

            <Box sx={styles.logos}>
              <Icons.LogosRedsysVisa />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

View.propTypes = topUpBalancePropTypes

export default View
